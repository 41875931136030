<template>
  <v-app>
    <transition name="fade-in-up">
      <div class="row">
        <div class="col-md-12">
          <v-card>
            <v-card-title>
              Stock Changes
              <v-spacer></v-spacer>
              <v-text-field
                v-model="search"
                append-icon="search"
                label="Search"
                single-line
                @change="searchStockChanges()"
                hide-details
              ></v-text-field>
            </v-card-title>
            <v-data-table
              :mobile-breakpoint="0"
              :headers="datatable.headers"
              :items="datatable.desserts"
              :server-items-length="datatable.total"
              :loading="datatable.isLoading"
              :options.sync="options"
              :footer-props="{
                showFirstLastPage: true,
                itemsPerPageOptions: [5, 10, 20, 50, 100]
              }"
              item-key="id"
            >
              <template v-slot:item.status="{ item }">
                <v-chip
                  class="ma-1"
                  :color="item.status ? 'green' : 'red'"
                  text-color="white"
                  v-b-tooltip.hover
                  :title="item.error"
                >
                  {{ item.status ? "success" : "failed" }}
                </v-chip>
              </template>
              <template v-slot:item.created_at="{ item }">
                {{ convertDate(item.created_at) }}
              </template>
            </v-data-table>
          </v-card>
        </div>
      </div>
    </transition>
  </v-app>
</template>

<style scoped lang="scss">
@import "../../../../node_modules/vuetify/dist/vuetify.css";

.theme--light.v-application {
  background: transparent !important;
}

.v-application code {
  box-shadow: none !important;
}
</style>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

import ApiService from "../../../core/services/api.service";
import Vue from "vue";
import _ from "lodash";
import moment from "moment-timezone";

const ignoreWarnMessage = `The .native modifier for v-on is only valid on components but it was used on <div>.`;
// eslint-disable-next-line no-unused-vars
Vue.config.warnHandler = function(msg, vm, trace) {
  if (msg === ignoreWarnMessage) {
    msg = null;
    vm = null;
    trace = null;
  }
};

export default {
  name: "StockStatusChangeLog",
  data() {
    return {
      options: {},
      datatable: {
        total: 0,
        search: "",
        isLoading: false,
        headers: [
          {
            text: "Message",
            align: "left",
            sortable: false,
            value: "message",
            width: "35%"
          },
          {
            text: "Changed",
            value: "stock_changed_product_sku",
            width: "13%"
          },
          {
            text: "Cause",
            value: "cause_product_sku",
            width: "13%"
          },
          {
            text: "Warehouse",
            value: "cause_product_warehouse_name",
            width: "12%"
          },
          {
            text: "Quantity",
            value: "cause_product_quantity",
            width: "8%"
          },
          {
            text: "Status",
            value: "status",
            width: "8%"
          },
          {
            text: "Created",
            value: "created_at",
            width: "15%"
          }
        ],
        desserts: []
      }
    };
  },
  components: {},
  watch: {
    options: {
      handler() {
        this.loadStockChanges();
      },
      deep: true
    }
  },
  methods: {
    loadStockChanges() {
      this.datatable.isLoading = true;
      let sortBy = "";
      let sortDesc = false;
      if (this.options.sortBy.length == 0) {
        sortBy = "created_at";
        sortDesc = true;
      } else {
        sortBy = this.options.sortBy[0];
        sortDesc = this.options.sortDesc[0];
      }

      ApiService.get("logs/stock-status-changes", {
        per_page: this.options.itemsPerPage,
        page: this.options.page,
        sortBy: sortBy,
        sort: sortDesc ? "DESC" : "ASC",
        search: this.search,
        type: this.$route.query.type
      })
        .then(({ data }) => {
          this.datatable.total = data.total;
          this.datatable.desserts = data.data;
        })
        .finally(() => {
          this.datatable.isLoading = false;
        });
    },
    searchStockChanges() {
      this.options.page = 1;
      this.loadStockChanges();
    },
    convertDate(date) {
      return (
        moment(date)
          // .add(hours, "hours")
          .format("YYYY-MM-DD HH:mm:ss")
      );
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Logs", route: "logs" },
      { title: "Stock Changes" }
    ]);
    // this.loadStockChanges();
  },
  created() {
    this.loadStockChanges = _.debounce(this.loadStockChanges, 300);
  },
  beforeRouteUpdate(to, from, next) {
    next();
    this.loadStockChanges();
  }
};
</script>
